<template>
  <th class="sortable-column thOrdenado" style="vertical-align: middle" @click="rotarOrden()">
    <b-button
      variant="primary"
      :title="$t('Seleccionar para búsqueda')"
      class="m-0 p-0 pr-1 btnLupa"
      size="sm"
      @click="seleccionarColumna($event, campo)"
      v-if="conBusqueda && !campo.includes('fecha')"
    >
      <v-icon variant="primary" name="search" width="14"></v-icon>
    </b-button>
    <slot>
      <span :title="help">{{ titulo }}</span>
    </slot>
    <v-icon v-show="ordenSeleccionado" v-if="dir == 'DESC'" variant="white" name="chevron-up" width="16" />
    <v-icon v-show="ordenSeleccionado" v-if="dir == 'ASC'" variant="white" name="chevron-down" width="16" />
  </th>
</template>
<script>
export default {
  props: {
    titulo: String,
    campo: String,
    conBusqueda: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dir() {
      return this.$route.query.dir || "";
    },
    help() {
      var t = this.dir == "DESC" ? "descendente" : "ascendente";
      return `Ordena por ${this.titulo} ${t}`;
    },
    ordenSeleccionado() {
      if (this.$route.query.orden == this.campo) {
        return true;
      }
      return false;
    },
  },
  methods: {
    rotarOrden() {
      var query = Object.assign({}, this.$route.query);
      query.orden = this.campo;
      query.dir = query.dir == "DESC" ? "ASC" : "DESC";

      var route = {
        path: this.$route.path,
        query: query,
      };

      this.$router.push(route);
    },
    seleccionarColumna(e, campo) {
      e.stopPropagation();
      this.$emit("seleccionarColumna", this.campo);
    },
  },
};
</script>
