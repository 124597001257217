import { api } from "./backend";

/**
 * Realiza el login
 */
export async function login(usuario, clave) {
  const response = await api.post("acceso/entrar", { usuario, clave });
  return response.data;
}

/**
 * Recupera la sesión si el usuario estaba activo
 */
export async function recover() {
  const response = await api.get("acceso/recuperar");
  return response.data;
}

/**
 * Realiza el logout
 */
export async function logout() {
  return await api.get("acceso/salir");
}
