const Listar = () => import("@/views/usuarios/Listar.vue");
const Crear = () => import("@/views/usuarios/Crear.vue");
const Editar = () => import("@/views/usuarios/Editar.vue");

export default [
  {
    path: "/usuarios",
    component: Listar,
  },
  {
    path: "/usuarios/crear",
    component: Crear,
  },
  {
    path: "/usuarios/editar/:id",
    component: Editar,
  },
];
