<template>
  <div class="rowRegistro">
    <b-row>
      <b-col cols="7" md="10" class="">
        <p>
          <b>{{ titulo }}</b>
        </p>
        <slot></slot>
      </b-col>
      <b-col cols="5" md="2" class="text-right colAcciones">
        <slot name="toolbar"></slot>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["titulo"],
};
</script>
