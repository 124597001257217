// Axios
import axios from "axios";
import datosDemo from "./demo";

export const demo = parseInt(process.env.VUE_APP_USAR_DATOS_DEMO);

const base_url = process.env.VUE_APP_ENDPOINT;

async function simulate(method, url, params) {
  console.log(`Simulando ${method} ${url}`);

  let response = {};

  if (datosDemo.hasOwnProperty(url)) {
    response = datosDemo[url];
  }

  return { data: response };
}

function createSampleApi() {
  return {
    async get(url, params) {
      return await simulate("GET", url, params);
    },
    async post(url, params) {
      return await simulate("POST", url, params);
    },
  };
}

function createRealApi() {
  axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

  const client = axios.create({
    baseURL: base_url,
    withCredentials: true,
  });

  return {
    get(url, data) {
      return client.get(url, { params: data });
    },
    post(url, data) {
      return client.post(url, data);
    },
    getClient() {
      return client;
    },
  };
}

function createApi() {
  if (demo) {
    return createSampleApi();
  } else {
    return createRealApi();
  }
}

export const api = createApi();
