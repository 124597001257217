export function listar(tipoExpediente) {
  return {
    camposPersonalizados: [
      {
        id: 1,
        nombre: "Campo personalizado 1",
        opciones: [
          { id: 1, nombre: "Opción A" },
          { id: 2, nombre: "Opción B" },
        ],
      },
      {
        id: 2,
        nombre: "Campo personalizado 2",
        opciones: [
          { id: 3, nombre: "Opción C" },
          { id: 4, nombre: "Opción D" },
        ],
      },
      {
        id: 3,
        nombre: "Campo personalizado 3",
        opciones: [
          { id: 5, nombre: "Opción prueba" },
          { id: 6, nombre: "Opción prueba 2" },
        ],
      },
      {
        id: 4,
        nombre: "Campo personalizado 4",
        opciones: [
          { id: 7, nombre: "Opción prueba 3" },
          { id: 8, nombre: "Opción prueba 4" },
        ],
      },
    ],
  };
}
