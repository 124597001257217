<template>
  <b-container fluid>
    <div id="page-container">
      <div id="page-content">
        <div class="content-card">
          <b-row class="top-listado" align-v="start">
            <b-col cols="8" md="7" sm="6">
              <h2>{{ title }}</h2>
            </b-col>
            <b-col cols="4" md="2" sm="6" class="pt-1" style="text-align: right">
              <slot name="toolbar"></slot>
            </b-col>
            <b-col md="3" sm="12" cols="12" v-if="conBusqueda">
              <busqueda-rapida @buscar="buscar"></busqueda-rapida>
            </b-col>
          </b-row>
          <slot></slot>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
export default {
  props: {
    title: String,
    conBusqueda: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    buscar(term) {
      if (this.$listeners && this.$listeners.buscar) {
        this.$emit("buscar", term);
        return;
      }

      var query = Object.assign({}, this.$route.query);
      if (term != "") {
        query.busqueda = term;
      } else {
        delete query["busqueda"];
      }

      query.pagina = 1;
      this.$router.push({
        path: this.$route.path,
        query: query,
      });
    },
  },
};
</script>
