import Login from "@/views/Login.vue";
import Home from "@/views/Home.vue";

export default [
  {
    path: "/",
    component: Home,
    // redirect: "/cuadro-mando",
  },
  {
    path: "/login",
    component: Login,
  },
];
