export function listar(idPantilla) {
  return {
    preguntas: [
      {
        id: 1,
        text: "¿Pregunta 1?",
      },
      {
        id: 2,
        text: "¿Pregunta 2?",
      },
      {
        id: 3,
        text: "¿Pregunta 3?",
      },
      {
        id: 4,
        text: "¿Pregunta 4?",
      },
    ],
  };
}
// export function listar(idPantilla) {
//   return {
//     preguntas: ["¿Pregunta 1?", "¿Pregunta 2?", "¿Pregunta 3?", "¿Pregunta 4?"],
//   };
// }
