const Listar = () => import("@/views/simulacion/Listar");
const Crear = () => import("@/views/simulacion/Crear");
const Editar = () => import("@/views/simulacion/Editar");

export default [
  {
    path: "/simulaciones",
    component: Listar,
    props: (route) => Object.assign({}, route.query),
  },
  {
    path: "/simulaciones/crear",
    component: Crear,
  },
  {
    path: "/simulaciones/editar/:id",
    component: Editar,
  },
];
