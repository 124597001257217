export function listar() {
  return {
    mediciones: [
      {
        id: 1,
        indicador: "Indicador - UNO",
        indicadorId: 1,
        fecha: "2021-04-21",
        valor: "7",
        meta: "2",
        observaciones: "Observaciones de prueba 1",
        zonaId: "2",
        zonaNombre: "Zona 2",
        causaId: "4",
        causaNombre: "Causa 4",
        cumplimiento: "5",
      },
      {
        id: 2,
        indicador: "Indicador - DOS",
        indicadorId: 2,
        fecha: "2021-04-26",
        valor: "1",
        meta: "1",
        observaciones: "Observaciones de prueba 2",
        zonaId: "1",
        zonaNombre: "Zona 1",
        causaId: "3",
        causaNombre: "Causa 3",
        cumplimiento: "0",
      },
      {
        id: 3,
        indicador: "Indicador - TRES",
        indicadorId: 3,
        fecha: "2020-05-11",
        valor: "9",
        meta: "1",
        observaciones: "Observaciones de prueba 3",
        zonaId: "5",
        zonaNombre: "Zona 5",
        causaId: "2",
        causaNombre: "Causa 2",
        cumplimiento: "8",
      },
      {
        id: 4,
        indicador: "Indicador - CUATRO",
        indicadorId: 4,
        fecha: "2021-02-10",
        valor: "6",
        meta: "2",
        observaciones: "Observaciones de prueba 4",
        zonaId: "4",
        zonaNombre: "Zona 4",
        causaId: "3",
        causaNombre: "Causa 3",
        cumplimiento: "4",
      },
      {
        id: 5,
        indicador: "Indicador - CINCO",
        indicadorId: 5,
        fecha: "2021-03-06",
        valor: "5",
        meta: "1",
        observaciones: "Observaciones de prueba 5",
        zonaId: "3",
        zonaNombre: "Zona 3",
        causaId: "2",
        causaNombre: "Causa 2",
        cumplimiento: "4",
      },
      {
        id: 6,
        indicador: "Indicador - SEIS",
        indicadorId: 6,
        fecha: "2021-02-27",
        valor: "4",
        meta: "1",
        observaciones: "Observaciones de prueba 6",
        zonaId: "1",
        zonaNombre: "Zona 1",
        causaId: "3",
        causaNombre: "Causa 3",
        cumplimiento: "3",
      },
      {
        id: 7,
        indicador: "Indicador - SIETE",
        indicadorId: 7,
        fecha: "2021-04-26",
        valor: "10",
        meta: "3",
        observaciones: "Observaciones de prueba 7",
        zonaId: "1",
        zonaNombre: "Zona 1",
        causaId: "3",
        causaNombre: "Causa 3",
        cumplimiento: "7",
      },
    ],
    hayPaginaAnterior: false,
    hayPaginaSiguiente: false,
    numeroPaginas: 1,
    pagina: 1,
    resultadosPorPagina: 15,
    totalItems: 4,
  };
}

export function cargar() {
  return {
    id: 6,
    indicador: "Medición del indicador dos - SEIS",
    indicadorId: 2,
    fecha: "2021-02-27",
    valor: "4",
    meta: "1",
    observaciones: "Observaciones de prueba 6",
    zonaId: "1",
    zonaNombre: "Zona 1",
    causaId: "3",
    causaNombre: "Causa 3",
    cumplimiento: "3",
  };
}
export function listarTipoChecklist() {
  return {
    mediciones: [
      {
        id: 2,
        indicador: "Indicador - DOS",
        indicadorId: 4,
        fecha: "2021-04-21",
        valor: "7",
        meta: "2",
        observaciones: "Observaciones de prueba 1",
        zonaId: "2",
        zonaNombre: "Zona 2",
        causaId: "4",
        causaNombre: "Causa 4",
        cumplimiento: "5",
      },
      {
        id: 3,
        indicador: "Indicador - TRES",
        indicadorId: 4,
        fecha: "2020-05-11",
        valor: "9",
        meta: "1",
        observaciones: "Observaciones de prueba 3",
        zonaId: "5",
        zonaNombre: "Zona 5",
        causaId: "2",
        causaNombre: "Causa 2",
        cumplimiento: "8",
      },
      {
        id: 5,
        indicador: "Indicador - CINCO",
        indicadorId: 7,
        fecha: "2021-03-06",
        valor: "5",
        meta: "1",
        observaciones: "Observaciones de prueba 5",
        zonaId: "3",
        zonaNombre: "Zona 3",
        causaId: "2",
        causaNombre: "Causa 2",
        cumplimiento: "4",
      },
    ],
    hayPaginaAnterior: false,
    hayPaginaSiguiente: false,
    numeroPaginas: 1,
    pagina: 1,
    resultadosPorPagina: 15,
    totalItems: 4,
  };
}
