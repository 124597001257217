export function listar() {
  return {
    usuarios: [
      {
        id: 1,
        creadoEl: "13/04/2021",
        nombre: "Rafael",
        apellidos: "Álvarez",
        email: "ralvarez@akakaka.com",
        clave: "1234",
        telefono: "661212121",
        cargo: "Cargo 1",
        bloqueado: null,
        esAdmin: null,
      },
      {
        id: 2,
        creadoEl: "14/04/2021",
        nombre: "Ignacio",
        apellidos: "Ariza",
        email: "iariza@akakaka.com",
        clave: "1234",
        telefono: "612548741",
        cargo: "Cargo 2",
        bloqueado: null,
        esAdmin: null,
      },
      {
        id: 3,
        creadoEl: "15/04/2021",
        nombre: "Carlos",
        apellidos: "Rojano",
        email: "crojano@akakaka.com",
        clave: "1234",
        telefono: "712345688",
        cargo: "Cargo 3",
        bloqueado: null,
        esAdmin: null,
      },
      {
        id: 4,
        creadoEl: "16/04/2021",
        nombre: "Jose Luis",
        apellidos: "Martínez",
        email: "jlmartinez@akakaka.com",
        clave: "1234",
        telefono: "612548974",
        cargo: "Cargo 4",
        bloqueado: null,
        esAdmin: null,
      },
    ],
    hayPaginaAnterior: false,
    hayPaginaSiguiente: false,
    numeroPaginas: 1,
    pagina: 1,
    resultadosPorPagina: 15,
    totalItems: 4,
  };
}

export function cargar() {
  return {
    id: 1,
    creadoEl: "2021-04-13",
    nombre: "Rafael",
    apellidos: "Álvarez",
    email: "ralvarez@akakaka.com",
    clave: "1234",
    telefono: "",
    cargo: "",
    bloqueado: null,
    esAdmin: null,
  };
}
