const Listar = () => import("@/views/administracion/indicadores/Listar.vue");
const Crear = () => import("@/views/administracion/indicadores/Crear.vue");
const Editar = () => import("@/views/administracion/indicadores/Editar.vue");

export default [
  // Indicadores
  {
    path: "/administracion/indicadores",
    component: Listar,
  },
  {
    path: "/administracion/indicadores/crear",
    component: Crear,
  },
  {
    path: "/administracion/indicadores/editar/:id",
    component: Editar,
  },
];
