<template>
  <div id="app">
    <b-container fluid class="container-app">
      <menu-top class="menu-mobile-top" @logout="logout" v-if="loggedIn"></menu-top>
      <!--<b-row style="min-height: calc(100vh - 50px)">-->
      <b-row class="main-row">
        <b-col class="app-sidebar" v-if="loggedIn">
          <page-sidebar @logout="logout"></page-sidebar>
        </b-col>
        <b-col class="app-content">
          <router-view @userMessage="showUserMessage"></router-view>
        </b-col>
      </b-row>
    </b-container>
    <b-modal @ok="hideUserMessage" :ok-only="true" ref="mbox" title="Atención" :no-enforce-focus="true">
      {{ userMessage }}
    </b-modal>
    <page-loading :active="loading"></page-loading>
  </div>
</template>

<script>
import PageSidebar from "@/views/common/sidebar.vue";
import MenuTop from "@/views/common/MenuMobileTop.vue";
import { api, demo } from "@/services/backend";
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState(["loading"]),
    ...mapState("session", ["csrfToken"]),
    ...mapGetters("session", ["loggedIn"]),
  },
  components: {
    PageSidebar,
    MenuTop,
  },
  data() {
    return {
      mostrandoMensajeDeSesion: false,
      userMessage: null,
    };
  },
  methods: {
    mostrarError(message) {
      this.$bvToast.toast(message, {
        title: "Atención",
        variant: "danger",
        solid: true,
        toaster: "b-toaster-top-center",
      });
    },
    mostrarErrorDeSesion() {
      if (this.$route.path == "/login") {
        return;
      }

      if (this.mostrandoMensajeDeSesion) {
        return;
      }

      this.mostrandoMensajeDeSesion = true;

      const body = "Debe iniciar sesión";
      this.$bvModal
        .msgBoxOk(body, {
          title: "Atención",
          okTitle: "Iniciar sesión",
          noCloseOnBackdrop: true,
        })
        .then(() => {
          this.$store.dispatch("session/logout").then(() => {
            this.mostrandoMensajeDeSesion = false;
            this.$router.push("/login");
          });
        });
    },
    showUserMessage(msg) {
      this.userMessage = msg;
      this.$refs["mbox"].show();
    },
    hideUserMessage() {
      this.$refs["mbox"].hide();
      this.userMessage = "";
    },
    logout() {
      this.$store.dispatch("session/logout").then(() => {
        this.$router.push("/login");
      });
    },
    setupInterceptors() {
      if (demo) {
        // Si estoy en modo demo, no necesito esto
        return;
      }

      const client = api.getClient();

      client.interceptors.request.use(
        (config) => {
          if (this.csrfToken) {
            config.headers.common["x-csrf-token"] = this.csrfToken;
          }
          this.$store.commit("setLoading", true);
          return config;
        },
        (error) => {
          this.$store.commit("setLoading", false);
          return Promise.reject(error);
        }
      );

      client.interceptors.response.use(
        (response) => {
          this.$store.commit("setLoading", false);
          return response;
        },
        (error) => {
          this.$store.commit("setLoading", false);

          if (error.response && error.response.headers && "x-error-message" in error.response.headers) {
            const message = error.response.headers["x-error-message"];
            const type = error.response.headers["x-error-type"];

            if (type == "SessionNotFound") {
              this.mostrarErrorDeSesion(message);
            } else {
              this.mostrarError(message);
            }
          }

          return Promise.reject(error);
        }
      );
    },
  },
  mounted() {
    this.$root.$on("userMessage", (msg) => {
      this.showUserMessage(msg);
    });
    this.setupInterceptors();
  },
};
</script>
