<template>
  <b-container style="max-width: 400px">
    <b-card class="mt-4">
      <img class="logo-login" src="/images/logo-delfos.png" />

      <h3 class="mt-4 text-center" style="font-size: 1.5em">
        {{ $t("Indicadores Predictivos") }}
      </h3>
      <hr />
      <b-form @submit.prevent="iniciarSesion">
        <b-form-group :label="$t('Usuario')">
          <b-form-input
            required
            autofocus
            autocomplete="username"
            type="text"
            v-model="usuario"
            :placeholder="$t('Usuario')"
          ></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('Contraseña')">
          <b-form-input
            v-model="clave"
            type="password"
            autocomplete="current-password"
            :placeholder="$t('Contraseña')"
          ></b-form-input>
        </b-form-group>
        <p v-if="invalidLogin">
          <small class="text-danger"> * {{ $t("Usuario o Contraseña incorrectos") }} </small>
        </p>
        <b-button type="submit" variant="primary" class="btn-login">
          {{ $t("Acceder") }}
        </b-button>
      </b-form>
    </b-card>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      usuario: "",
      clave: "",
    };
  },
  computed: {
    ...mapGetters("session", ["loggedIn", "invalidLogin"]),
    ...mapState("session", [""]),
  },
  methods: {
    async iniciarSesion() {
      this.$store.dispatch("session/login", [this.usuario, this.clave]);
    },
  },
  mounted() {},
  watch: {
    loggedIn(value) {
      if (value) {
        this.$router.push({ path: this.$route.query.redirect || "/" });
      }
    },
  },
};
</script>
