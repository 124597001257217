const ListarMediciones = () => import("@/views/administracion/indicadores/mediciones/Listar.vue");
const CrearMedicion = () => import("@/views/administracion/indicadores/mediciones/Crear.vue");
const EditarMedicion = () => import("@/views/administracion/indicadores/mediciones/Editar.vue");
const ImportarMediciones = () => import("@/views/administracion/indicadores/mediciones/Importar.vue");

export default [
  // Mediciones
  {
    path: "/administracion/indicadores/:indicadorId/mediciones",
    component: ListarMediciones,
  },
  {
    path: "/administracion/indicadores/:indicadorId/mediciones/crear",
    component: CrearMedicion,
  },
  {
    path: "/administracion/indicadores/:indicadorId/mediciones/editar/:id",
    component: EditarMedicion,
  },
  {
    path: "/administracion/indicadores/:indicadorId/mediciones/importar",
    component: ImportarMediciones,
  },
];
