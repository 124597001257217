<template>
  <b-button variant="outline-danger" class="actionButton" size="sm" @click="confirmar">
    <v-icon variant="primary" name="trash-2" width="16"></v-icon>
    <span>{{ textoBoton }}</span>
    <b-modal ref="modal" :title="tituloModal" @ok="$emit('ok')">
      <slot></slot>
    </b-modal>
  </b-button>
</template>
<script>
export default {
  props: {
    url: String,
    tituloModal: {
      type: String,
      default: "Eliminar",
    },
    textoBoton: {
      type: String,
      default: "Eliminar",
    },
  },
  methods: {
    confirmar() {
      this.$refs.modal.show({
        title: "Prueba",
        okTitle: "Aceptar",
        cancelTitle: "Cancelar",
      });
    },
  },
};
</script>
