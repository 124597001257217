export function listar() {
  return {
    zonas: [
      {
        id: 1,
        nombre: "Planta Concón",
        activa: 1,
      },
      {
        id: 2,
        nombre: "Zona - DOS",
        activa: 0,
      },
      {
        id: 3,
        nombre: "Zona - TRES",
        activa: 0,
      },
      {
        id: 4,
        nombre: "Zona - CUATRO",
        activa: 1,
      },
      {
        id: 5,
        nombre: "Zona - CINCO",
        activa: 0,
      },
      {
        id: 6,
        nombre: "Zona - SEIS",
        activa: 1,
      },
      {
        id: 7,
        nombre: "Zona - SIETE",
        activa: 1,
      },
    ],
    hayPaginaAnterior: false,
    hayPaginaSiguiente: false,
    numeroPaginas: 1,
    pagina: 1,
    resultadosPorPagina: 15,
    totalItems: 4,
  };
}

export function cargar() {
  return {
    id: 3,
    nombre: "Zona - TRES",
    activa: 1,
  };
}
