import * as causas from "./causas.js";
import * as cuadroMando from "./cuadroMando.js";
import * as indicadores from "./indicadores.js";
import * as mediciones from "./mediciones.js";
import * as usuarios from "./usuarios.js";
import * as zonas from "./zonas.js";
import * as origenesDatos from "./origenesDatos.js";
import * as plantillasRadar from "./plantillasRadar.js";
import * as preguntas from "./preguntas.js";
import * as tiposExpediente from "./tiposExpediente.js";
import * as camposPersonalizados from "./camposPersonalizados.js";
import * as discriminarPor from "./discriminarPor.js";

export default {
  "acceso/entrar": {
    csrfToken: "f5ce807e-5846-4a31-bf72-c329442337e4",
    usuarioId: "201",
  },
  "acceso/recuperar": {
    csrfToken: "f5ce807e-5846-4a31-bf72-c329442337e4",
    usuarioId: "201",
  },
  "causa/listar": causas.listar(),
  "causa/cargar": causas.cargar(),
  "cuadroMando/listar": cuadroMando.listar(),
  "cuadroMando/cargar": cuadroMando.cargar(),
  "cuadroMando/valores": cuadroMando.valores(),
  "indicadorCuadroMando/cargarDisenio": cuadroMando.cargarDisenio(),
  "indicador/listar": indicadores.listar(),
  "indicador/listarAdelantados": indicadores.listarAdelantados(),
  "indicador/cargar": indicadores.cargar(),
  "medicion/listar": mediciones.listar(),
  "medicion/listarTipoChecklist": mediciones.listarTipoChecklist(),
  "medicion/cargar": mediciones.cargar(),
  "usuario/listar": usuarios.listar(),
  "usuario/cargar": usuarios.cargar(),
  "zona/listar": zonas.listar(),
  "origenDatos/listar": origenesDatos.listar(),
  "origenDatos/listarChecklist": origenesDatos.listarChecklist(),
  "origenDatos/listarIncidentes": origenesDatos.listarIncidentes(),
  "origenDatos/cargar": origenesDatos.cargar(),
  "plantillaRadar/listar": plantillasRadar.listar(),
  "pregunta/listar": preguntas.listar(),
  "tipoExpediente/listar": tiposExpediente.listar(),
  "campoPersonalizado/listar": camposPersonalizados.listar(),
  "discriminarPor/listar": discriminarPor.listar(),
};
