<template>
  <b-nav class="more-menu-top pt-1 pb-1 float-left">
    <b-nav-item-dropdown right class="float-left">
      <template slot="button-content" class="float-left m-0 p-0">
        {{ $t("Ordenar") }}:
        <span class="ordenado-por">{{ orden }} {{ dir }}</span>
      </template>
      <b-dropdown-item v-for="campo in campos" v-bind:key="campo.campo">
        <registro-ordenado :titulo="campo.text" :campo="campo.campo"></registro-ordenado>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-nav>
</template>
<script>
export default {
  props: {
    campos: {
      type: Array,
      required: true,
    },
  },
  computed: {
    dir() {
      return this.$route.query.dir || "";
    },
    orden() {
      return this.$route.query.orden || "";
    },
  },
};
</script>
