<template>
  <span class="sortable-column" @click="rotarOrden()" :title="help">
    <slot>{{ titulo }}</slot>
  </span>
</template>
<script>
export default {
  props: {
    titulo: String,
    campo: String,
  },
  computed: {
    help() {
      var t = this.dir == "DESC" ? "descendente" : "ascendente";
      return `Ordena por ${this.titulo} ${t}`;
    },
  },
  methods: {
    rotarOrden() {
      var query = Object.assign({}, this.$route.query);
      query.orden = this.campo;
      query.dir = query.dir == "DESC" ? "ASC" : "DESC";

      var route = {
        path: this.$route.path,
        query: query,
      };

      this.$router.push(route);
    },
  },
};
</script>
