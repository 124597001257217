import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

import inicio from "./inicio";
import usuarios from "./usuarios";
import cuadrosMando from "./cuadrosMando";
import indicadores from "./indicadores";
import mediciones from "./mediciones";
import zonas from "./zonas";
import causas from "./causas";
import origenes from "./origenes";
import simulaciones from "./simulaciones";

Vue.use(VueRouter);

const routes = [
  ...inicio,
  ...cuadrosMando,
  ...indicadores,
  ...mediciones,
  ...zonas,
  ...causas,
  ...usuarios,
  ...origenes,
  ...simulaciones,
];

const router = new VueRouter({ routes });

router.makeStickUrl = function (url) {
  var params = new URLSearchParams(this.currentRoute.query).toString();
  if (params) {
    const sep = url.indexOf("?") == -1 ? "?" : "&";
    url += sep + params;
  }
  return url;
};

router.beforeEach(async (to, from, next) => {
  if (to.path == "/login") {
    if (store.getters["session/loggedIn"]) {
      next({ path: "/" });
    } else {
      next();
    }
    return;
  }

  /**Esto permite comprueba y recupera la sessión si recargamos la pantalla**/
  if (!store.getters["session/loggedIn"]) {
    await store.dispatch("session/recover");
  }

  if (!store.getters["session/loggedIn"]) {
    const query = {};

    if (to.fullPath != "/") {
      query.redirect = to.fullPath;
    }

    next({ path: "/login", query: query });
  } else {
    next();
  }
});

export default router;
