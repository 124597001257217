<template>
  <b-form-group :label="$t('Estado')" class="comboFiltro" label-cols="4">
    <b-form-select class="mb-2" required size="sm" ref="comboActivo" v-model="value" @change="cambiarActivo">
      <b-form-select-option value="1" key="1"> Activo </b-form-select-option>
      <b-form-select-option value="0" key="0"> Inactivo </b-form-select-option>
    </b-form-select>
  </b-form-group>
</template>
<script>
export default {
  props: {
    field: {
      type: String,
      default: "activo",
    },
  },
  data() {
    return {
      value: 1,
    };
  },
  methods: {
    cambiarActivo(value) {
      var query = Object.assign({}, this.$route.query);
      query[this.field] = value;

      query.pagina = 1;
      var route = {
        path: this.$route.path,
        query: query,
      };

      this.$router.push(route);
    },
  },
  mounted() {
    if (this.$route.query.hasOwnProperty(this.field)) {
      this.value = this.$route.query[this.field];
    }
  },
};
</script>
