<template>
  <div>
    <div class="brand mb-3 mt-2">
      <b-link to="/">
        <!-- xl -->
        <img src="/images/delfos-blanco.png" class="mt-1 ml-2 d-none d-lg-block" />
        <!-- md -->
        <img src="/images/delfos-blanco-circulo.png" class="mt-1 ml-2 d-none d-sm-block d-lg-none" />
      </b-link>
      <div class="sub-brand-text d-none d-sm-block d-lg-none">Delfos</div>
      <div class="sub-brand-text pt-1 pb-1 sm-hidden">Indicadores Predictivos</div>
    </div>

    <b-nav vertical class="p-0" v-if="loggedIn">
      <b-nav-item to="/cuadro-mando" :title="$t('Dashboard')">
        <v-icon name="monitor" width="16" />
        <span>{{ $t("Dashboard") }}</span>
      </b-nav-item>
      <!-- <b-nav-item to="/Informes" :title="$t('Informes')">
        <v-icon name="file-text" width="16" />
        <span>{{ $t("Informes") }}</span>
      </b-nav-item>-->
      <menu-administracion />
    </b-nav>

    <b-nav vertical class="p-0 b-nav-logout" v-if="loggedIn">
      <!-- CERRAR SESSIÓN -->
      <li><hr class="mb-0 mt-0" /></li>
      <b-nav-item @click="logout" :title="$t('Cerrar Sesión')">
        <v-icon name="log-out" width="16" v-b-tooltip.hover />
        <span class="sm-hidden">{{ $t("Cerrar Sesión") }}</span>
      </b-nav-item>
    </b-nav>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import MenuAdministracion from "@/components/sidebar/MenuAdministracion";

export default {
  components: {
    MenuAdministracion,
  },
  computed: {
    ...mapGetters("session", ["loggedIn"]),
  },
  data() {
    return {};
  },
  methods: {
    async logout() {
      const ok = await this.$bvModal.msgBoxConfirm("¿Desea cerrar la sesión?", {
        title: "Atención",
        okTitle: "Cerrar sesión",
        cancelTitle: "Cancelar",
      });

      if (ok) {
        this.$emit("logout");
      }
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        if (this.loggedIn) {
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
