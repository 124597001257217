const Listar = () => import("@/views/administracion/causas/Listar.vue");
const Crear = () => import("@/views/administracion/causas/Crear.vue");
const Editar = () => import("@/views/administracion/causas/Editar.vue");

export default [
  {
    path: "/administracion/causas",
    component: Listar,
  },
  {
    path: "/administracion/causas/crear",
    component: Crear,
  },
  {
    path: "/administracion/causas/editar/:id",
    component: Editar,
  },
];
