import { render, staticRenderFns } from "./AccionEliminar.vue?vue&type=template&id=01a1dc48&"
import script from "./AccionEliminar.vue?vue&type=script&lang=js&"
export * from "./AccionEliminar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports