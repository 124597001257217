import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import { formatDateEs } from "@/services/date";

Vue.config.productionTip = false;

Vue.filter("fecha", formatDateEs);
Vue.filter("activo", (e) => (e ? "Activo" : "Inactivo"));
Vue.filter("activa", (e) => (e ? "Activa" : "Inactiva"));
Vue.filter("sino", (e) => (e ? "Sí" : "No"));

// Bootstrap (https://bootstrap-vue.js.org/)
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import bsConfig from "./bsDefaults.js";
Vue.use(BootstrapVue, bsConfig);
Vue.prototype.$dateFormatOptions = {
  year: "numeric",
  month: "short",
  day: "2-digit",
  weekday: "short",
  locale: "es-ES",
  format: "DD/MM/YYYY",
};

// Icons
import feather from "vue-icon";
Vue.use(feather, "v-icon");

// Vue-loading
import PageLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
Vue.component("page-loading", PageLoading);

import "./componentes";

// Aplicacion
import "@/styles/main.scss";

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
