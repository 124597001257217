const Listar = () => import("@/views/administracion/origenesDatos/Listar.vue");
const Crear = () => import("@/views/administracion/origenesDatos/Crear.vue");
const Editar = () => import("@/views/administracion/origenesDatos/Editar.vue");

export default [
  {
    path: "/administracion/origenes-de-datos",
    component: Listar,
  },
  {
    path: "/administracion/origenes-de-datos/crear",
    component: Crear,
  },
  {
    path: "/administracion/origenes-de-datos/editar/:id",
    component: Editar,
  },
];
