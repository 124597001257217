<template>
  <div class="campo-imagen mt-1">
    <img :src="value.content" :alt="value.name" v-if="tieneImagen" />

    <b-form-group :label="title">
      <b-form-file
        ref="file"
        :placeholder="placeholder"
        v-bind="$attrs"
        v-on="$listeners"
        accept=".jpg, .png, .gif"
        @change="cambiarImagen"
      >
      </b-form-file>
      <span v-if="value && !disabled" class="btn btn-info" @click="quitarImagen"> Quitar </span>
    </b-form-group>
  </div>
</template>
<style>
.campo-imagen img {
  max-width: 128px;
  max-height: 128px;
  border: 1px solid black;
  background: white;
  padding: 0.25em;
  float: left;
  margin-right: 1em;
}
</style>
<script>
/**
 * Este componente permite recibir una imagen en formato codificado.
 *
 * Mediante la propiedad v-model (ó value) se puede indicar el formato
 * codificado.
 *
 * El formato codificado es el objecto {name, content}, donde:
 *
 *     - name: es el nombre del fichero
 *     - content: es la imagen codificada en base64 según
 *                el estándar image/data
 *     - status:
 *         0 = Ok
 *         1 = Sin imagen
 *         2 = Imagen no encontrada
 *
 *  Uso:
 *      <campo-imagen v-model="entidad.imagen" />
 *
 */
export default {
  props: {
    title: String,
    value: [File, Object],
    disabled: Boolean,
    placeholder: {
      type: String,
      default: "Elige una foto y arrástrala aquí...",
    },
  },
  computed: {
    tieneImagen() {
      return this.value && this.value.hasOwnProperty("content") && this.value.content != "";
    },
  },
  methods: {
    quitarImagen(event) {
      this.$emit("input", {
        name: "",
        content: "",
        removed: true,
      });
    },
    cambiarImagen(event) {
      const file = event.target.files[0];
      const name = file.name;

      var reader = new FileReader();

      reader.onload = (e) => {
        this.$emit("input", {
          name: name,
          content: e.target.result,
          changed: true,
        });
      };

      reader.readAsDataURL(event.target.files[0]);
    },
  },
  data() {
    return {};
  },
};
</script>
