export function listar() {
  return {
    origenesDatos: [
      {
        id: 1,
        nombre: "ORIGEN DE DATOS - UNO",
        tipoId: 2,
        activo: 1,
      },
      {
        id: 2,
        nombre: "ORIGEN DE DATOS - DOS",
        tipoId: 1,
        activo: 0,
      },
      {
        id: 3,
        nombre: "ORIGEN DE DATOS - TRES",
        tipoId: 1,
        activo: 0,
      },
      {
        id: 4,
        nombre: "ORIGEN DE DATOS - CUATRO",
        tipoId: 1,
        activo: 1,
      },
      {
        id: 5,
        nombre: "ORIGEN DE DATOS - CINCO",
        tipoId: 2,
        activo: 0,
      },
      {
        id: 6,
        nombre: "ORIGEN DE DATOS - SEIS",
        tipoId: 2,
        activo: 1,
      },
      {
        id: 7,
        nombre: "ORIGEN DE DATOS - SIETE",
        tipoId: 1,
        activo: 1,
      },
    ],
    hayPaginaAnterior: false,
    hayPaginaSiguiente: false,
    numeroPaginas: 1,
    pagina: 1,
    resultadosPorPagina: 15,
    totalItems: 4,
  };
}

export function cargar() {
  return {
    id: 3,
    nombre: "ORIGEN DE DATOS - CUATRO",
    tipoId: 1,
    activo: 0,
    plantillaRadarId: 2,
    preguntas: [1, 4],
    tipoExpedienteId: 3,
    causa: 2,
    discriminarPor: 4,
  };
}

export function listarChecklist() {
  return {
    origenesChekList: [
      {
        id: 1,
        nombre: "ORIGEN DE DATOS - UNO",
      },
      {
        id: 7,
        nombre: "ORIGEN DE DATOS - SIETE",
      },
      {
        id: 5,
        nombre: "ORIGEN DE DATOS - CINCO",
      },
    ],
  };
}

export function listarIncidentes() {
  return {
    origenesIncidentes: [
      {
        id: 4,
        nombre: "ORIGEN DE DATOS - CUATRO",
      },
      {
        id: 6,
        nombre: "ORIGEN DE DATOS - SEIS",
      },
      {
        id: 9,
        nombre: "ORIGEN DE DATOS - NUEVE",
      },
    ],
  };
}
