const Listar = () => import("@/views/administracion/zonas/Listar.vue");
const Crear = () => import("@/views/administracion/zonas/Crear.vue");
const Editar = () => import("@/views/administracion/zonas/Editar.vue");

export default [
  {
    path: "/administracion/zonas",
    component: Listar,
  },
  {
    path: "/administracion/zonas/crear",
    component: Crear,
  },
  {
    path: "/administracion/zonas/editar/:id",
    component: Editar,
  },
];
