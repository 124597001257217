export function listar(tipoExpediente) {
  return {
    discriminarPor: [
      {
        id: 1,
        nombre: "Campo descriminar 1",
        opciones: [
          { id: 1, nombre: "Opción 1" },
          { id: 2, nombre: "Opción 2" },
        ],
      },
      {
        id: 2,
        nombre: "Campo descriminar 2",
        opciones: [
          { id: 3, nombre: "Opción 3" },
          { id: 4, nombre: "Opción 4" },
        ],
      },
      {
        id: 3,
        nombre: "Campo descriminar 3",
        opciones: [
          { id: 5, nombre: "Opción 5" },
          { id: 6, nombre: "Opción 6" },
        ],
      },
      {
        id: 4,
        nombre: "Campo descriminar 4",
        opciones: [
          { id: 7, nombre: "Opción 7" },
          { id: 8, nombre: "Opción 8" },
        ],
      },
    ],
  };
}
