export function listar(idCentro) {
  return {
    tiposExpediente: [
      {
        id: 1,
        nombre: "Tipo 1",
      },
      {
        id: 2,
        nombre: "Tipo 3",
      },
      {
        id: 3,
        nombre: "Tipo 3",
      },
      {
        id: 4,
        nombre: "Tipo 4",
      },
    ],
  };
}
