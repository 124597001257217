<template>
  <b-form @submit.prevent="$emit('buscar', search)">
    <b-input-group>
      <b-form-input v-model="search" type="search" size="md" placeholder="Búsqueda"> </b-form-input>
      <b-input-group-append>
        <b-button variant="primary" size="sm" type="submit">
          <v-icon variant="primary" name="search" width="20"></v-icon>
        </b-button>
        <b-button class="tabla-mobile" variant="outline-primary" size="sm" type="submit" v-b-toggle.collapse-filtros>
          <v-icon variant="primary" name="filter" width="20"></v-icon>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <input type="submit" style="display: none" />
  </b-form>
</template>
<script>
export default {
  data() {
    return { search: "" };
  },
  mounted() {
    if (this.$route.query.busqueda) {
      this.search = this.$route.query.busqueda;
    }
  },
};
</script>
