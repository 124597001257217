<template>
  <div>
    <div class="brand">
      <b-link to="/">
        <img src="/images/delfos-blanco.png" class="mt-1 ml-2" />
      </b-link>
    </div>
    <Slide :closeOnNavigation="true" width="350">
      <b-link to="/">
        <img src="/images/delfos-blanco.png" class="mt-4" />
      </b-link>
      <b-link to="/cuadro-mando">
        <v-icon name="monitor" width="25" color="white" />
        <span>{{ $t("Dashboard") }}</span>
      </b-link>
      <small>Administración</small>
      <b-link to="/administracion/cuadros-de-mando">
        <v-icon name="monitor" width="25" color="white" />
        <span>{{ $t("Cuadros de mando") }}</span>
      </b-link>
      <b-link to="/administracion/indicadores">
        <v-icon name="check-square" width="25" color="white" />
        <span>{{ $t("Indicadores") }}</span>
      </b-link>
      <b-link to="/administracion/zonas">
        <v-icon name="map-pin" width="25" color="white" />
        <span>{{ $t("Zonas") }}</span>
      </b-link>
      <b-link to="/administracion/causas">
        <v-icon name="list" width="25" color="white" />
        <span>{{ $t("Causas") }}</span>
      </b-link>
      <b-link to="/administracion/origenes-de-datos">
        <v-icon name="database" width="25" color="white" />
        <span>{{ $t("Orígenes de datos") }}</span>
      </b-link>
      <b-link to="/usuarios">
        <v-icon name="users" width="25" color="white" />
        <span>{{ $t("Usuarios") }}</span>
      </b-link>
      <a class="bottom" @click="logout" :title="$t('Cerrar Sesión')">
        <v-icon name="log-out" width="25" color="white" v-b-tooltip.hover />
        <span class="sm-hidden">{{ $t("Cerrar Sesión") }}</span>
      </a>
    </Slide>
  </div>
</template>
<script>
import { Slide } from "vue-burger-menu";
// import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
  components: {
    Slide, // Register your component
  },
  methods: {
    async logout() {
      const ok = await this.$bvModal.msgBoxConfirm("¿Desea cerrar la sesión?", {
        title: "Atención",
        okTitle: "Cerrar sesión",
        cancelTitle: "Cancelar",
      });

      if (ok) {
        this.$emit("logout");
      }
    },
  },
};
</script>
