<template>
  <b-dropdown-item size="sm" @click.prevent="onClick">
    <v-icon variant="primary" name="trash-2" class="mr-1" width="16" />
    {{ $t("Eliminar") }}
  </b-dropdown-item>
</template>
<script>
export default {
  props: ["confirmacion"],
  methods: {
    async onClick() {
      const message = this.confirmacion || "¿Desea eliminar este registro?";
      var ok = await this.$bvModal.msgBoxConfirm(message);
      if (ok) {
        this.$emit("eliminar");
      }
    },
  },
};
</script>
