import { api } from "./backend";

const filtros_comunes_listado = ["busqueda", "activo", "activa"];

export async function listado(action, params, filtrosPorDefecto) {
  filtrosPorDefecto = filtrosPorDefecto || {};

  if (params) {
    params = Object.assign(filtrosPorDefecto, params);
  } else {
    params = filtrosPorDefecto;
  }

  params.filtros = params.filtros || {};
  params.busqueda = params.busqueda || "";
  params.activo = params.activo || "";
  params.pagina = params.pagina || 1;
  params.orden = params.orden || "";
  params.dir = params.dir || "ASC";

  for (var fkey of filtros_comunes_listado) {
    if (params[fkey] != "") {
      params.filtros[fkey] = params[fkey];
      delete params[fkey];
    }
  }

  if (params.orden != "") {
    params.orden = { [params.orden]: params.dir };
  } else {
    delete params["orden"];
  }

  const response = await api.post(action, params);

  return response.data;
}
