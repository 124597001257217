/**
 * Este fichero contiene la configuración por defecto
 * que queremos aplicar a vue-bootstrap
 */

export default {
  BModal: {
    cancelTitle: "Cancelar",
    okTitle: "Aceptar",
    headerCloseContent: "&times;",
    headerCloseLabel: "Cerrar",
  },
};
