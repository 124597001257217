import { listado } from "./listado";
import { api } from "./backend";

export function crear() {
  return {
    id: 0,
    nombre: "",
    activo: 1,
  };
}

export async function listar(params) {
  return await listado("cuadroMando/listar", params, { activo: 1 });
}

export async function cargar(id) {
  const response = await api.get("cuadroMando/cargar", { id });
  return response.data;
}

export async function valores(id, filtros) {
  const response = await api.post("cuadroMando/valores", { id, filtros });
  return response.data;
}

export async function insertar(cuadroMando) {
  const response = await api.post("cuadroMando/insertar", { cuadroMando });
  return response.data;
}

export async function guardarDisenio(cuadroMandoId, disenio) {
  await api.post("indicadorCuadroMando/guardarDisenio", { cuadroMandoId, disenio });
}

export async function cargarDisenio(cuadroMandoId) {
  const response = await api.post("indicadorCuadroMando/cargarDisenio", { cuadroMandoId });
  return response.data;
}

export async function actualizar(cuadroMando) {
  const response = await api.post("cuadroMando/actualizar", { cuadroMando });
  return response.data;
}

export async function eliminar(id) {
  await api.post("cuadroMando/eliminar", { id });
}

export async function predeterminar(id) {
  await api.post("cuadroMando/predeterminar", { id });
}

export async function zonasUsadas(id) {
  const response = await api.get("cuadroMando/zonasUsadas", { id });
  return response.data;
}

export async function causasUsadas(id) {
  const response = await api.get("cuadroMando/causasUsadas", { id });
  return response.data;
}
