export function listar() {
  return {
    causas: [
      {
        id: 1,
        nombre: "Causa - UNO",
        activa: 1,
      },
      {
        id: 2,
        nombre: "Causa - DOS",
        activa: 0,
      },
      {
        id: 3,
        nombre: "Causa - TRES",
        activa: 0,
      },
      {
        id: 4,
        nombre: "Causa - CUATRO",
        activa: 1,
      },
      {
        id: 5,
        nombre: "Causa - CINCO",
        activa: 0,
      },
      {
        id: 6,
        nombre: "Causa - SEIS",
        activa: 1,
      },
      {
        id: 7,
        nombre: "Causa - SIETE",
        activa: 1,
      },
    ],
    hayPaginaAnterior: false,
    hayPaginaSiguiente: false,
    numeroPaginas: 1,
    pagina: 1,
    resultadosPorPagina: 15,
    totalItems: 4,
  };
}

export function cargar() {
  return {
    id: 3,
    nombre: "Causa - CUATRO",
    activa: 0,
  };
}
