import Vue from "vue";
import Vuex from "vuex";
import session from "./session";
import dashboard from "./dashboard";

Vue.use(Vuex);

const modules = { session, dashboard };

const state = {
  loading: false,
  loading_count: 0,
};

const mutations = {
  setLoading(state, loading) {
    if (loading) {
      state.loading_count++;
      state.loading = true;
    } else if (state.loading_count > 0) {
      state.loading_count--;
      state.loading = state.loading_count > 0;
    }
  },
};

const actions = {};
const strict = process.env.NODE_ENV !== "production";

export default new Vuex.Store({ strict, state, mutations, actions, modules });
