<template>
  <div class="campo-archivo">
    <b-form-group :label="title" :label-cols="cols">
      <b-form-file
        ref="file"
        browse-text="Seleccionar"
        :required="required"
        :placeholder="placeholder"
        v-bind="$attrs"
        v-on="$listeners"
        :accept="accept"
        @change="cambiarArchivo"
      >
      </b-form-file>
    </b-form-group>
  </div>
</template>
<script>
/**
 * Este componente permite recibir una archivo en formato codificado.
 *
 * Mediante la propiedad v-model (ó value) se puede indicar el formato
 * codificado.
 *
 * El formato codificado es el objecto {name, content}, donde:
 *
 *     - name: es el nombre del fichero
 *     - content: es la imagen codificada en base64 según
 *                el estándar image/data
 *     - status:
 *         0 = Ok
 *         1 = Sin imagen
 *         2 = Imagen no encontrada
 *
 *  Uso:
 *      <campo-imagen v-model="entidad.imagen" />
 *
 */
export default {
  props: {
    title: String,
    value: [File, Object],
    disabled: Boolean,
    required: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Elige un archivo y arrástralo aquí...",
    },
    cols: { type: [Number, String], default: 12 },
    accept: String,
  },
  computed: {},
  methods: {
    cambiarArchivo(event) {
      const file = event.target.files[0];
      const name = file.name;

      var reader = new FileReader();

      reader.onload = (e) => {
        this.$emit("input", {
          name: name,
          content: e.target.result,
          changed: true,
        });
      };

      reader.readAsDataURL(event.target.files[0]);
    },
  },
  data() {
    return {};
  },
};
</script>
