// DayJS
import dayjs from "dayjs";
import "dayjs/locale/es";
// import customParseFormat from "dayjs/plugin/customParseFormat";
// import utc from 'dayjs/plugin/utc';

dayjs.locale("es");
// dayjs.extend(utc)
// dayjs.extend(customParseFormat);

// Formato que viene de de coldfusion
// "MonthName, DayNumber Year Hours:Minutes:Seconds";
// const cfml_format = "MMMM, D YYYY HH:mm:ss";

const date_format = "YYYY-MM-DD";
const date_format_tabla = "DD/MM/YYYY";
const date_format_form = "YYYY-MM-DD";
const datetime_format = "YYYY-MM-DD HH:mm:ss";
const datetime_format_es = "DD/MM/YYYY HH:mm:ss";
const date_format_es = "DD/MM/YYYY";


/**
 * No me queda más remedio que quitar la información del timezone
 * porque si no se vuelve loco dayjs con las fechas.
 */
function normalizeDate(s){

  if(typeof(s) == "string"){
    return s.replace(/[+-]\d+$/, "");
  }

  return s;
}

function _parse(s) {
  return dayjs(normalizeDate(s));
}

export function parseDate(s){
  return new Date(normalizeDate(s));
}

export function formatNewDateForm(date) {
  return _parse(date).format(date_format_form);
}

export function formatDateEs(date) {
  return _parse(date).format(date_format_es);
}
