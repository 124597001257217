const CuadroMando = () => import("@/views/cuadroMando/");
const Listar = () => import("@/views/administracion/cuadrosMando/Listar.vue");
const Crear = () => import("@/views/administracion/cuadrosMando/Crear.vue");
const Editar = () => import("@/views/administracion/cuadrosMando/Editar.vue");
const AsignarIndicadores = () => import("@/views/administracion/cuadrosMando/AsignarIndicadores.vue");

export default [
  {
    path: "/cuadro-mando/",
    component: CuadroMando,
    props: (route) => {
      return Object.assign({}, route.query);
    },
  },
  {
    path: "/administracion/cuadros-de-mando",
    component: Listar,
  },
  {
    path: "/administracion/cuadros-de-mando/crear",
    component: Crear,
  },
  {
    path: "/administracion/cuadros-de-mando/editar/:id",
    component: Editar,
  },
  {
    path: "/administracion/cuadros-de-mando/asignar-indicadores/:id",
    component: AsignarIndicadores,
  },
];
