import Vue from "vue";

//layout
import Page from "@/components/Page.vue";
Vue.component("Page", Page);

import PageEdicion from "@/components/PageEdicion.vue";
Vue.component("PageEdicion", PageEdicion);

import PageListado from "@/components/PageListado.vue";
Vue.component("PageListado", PageListado);

import BotoneraEdicion from "@/components/BotoneraEdicion.vue";
Vue.component("BotoneraEdicion", BotoneraEdicion);

import SinRegistros from "@/components/SinRegistros.vue";
Vue.component("SinRegistros", SinRegistros);

import BusquedaRapida from "@/components/BusquedaRapida.vue";
Vue.component("BusquedaRapida", BusquedaRapida);

import BusquedaColumna from "@/components/BusquedaColumna.vue";
Vue.component("BusquedaColumna", BusquedaColumna);

import ThOrdenado from "@/components/ThOrdenado.vue";
Vue.component("ThOrdenado", ThOrdenado);

import RegistroOrdenado from "@/components/RegistroOrdenado.vue";
Vue.component("RegistroOrdenado", RegistroOrdenado);

import BotonEliminar from "@/components/BotonEliminar.vue";
Vue.component("BotonEliminar", BotonEliminar);

import MensajeAlerta from "@/components/MensajeAlerta.vue";
Vue.component("MensajeAlerta", MensajeAlerta);

import CampoImagen from "@/components/CampoImagen.vue";
Vue.component("CampoImagen", CampoImagen);

import CampoArchivo from "@/components/CampoArchivo.vue";
Vue.component("CampoArchivo", CampoArchivo);

import FiltroEstado from "@/components/FiltroEstado.vue";
Vue.component("FiltroEstado", FiltroEstado);

import FiltroActivo from "@/components/FiltroActivo.vue";
Vue.component("FiltroActivo", FiltroActivo);

import OrdenarTablaMobile from "@/components/OrdenarTablaMobile.vue";
Vue.component("OrdenarTablaMobile", OrdenarTablaMobile);

import FilaCompacta from "@/components/FilaCompacta";
Vue.component("FilaCompacta", FilaCompacta);

import FiltrosListadoMobile from "@/components/FiltrosListadoMobile";
Vue.component("FiltrosListadoMobile", FiltrosListadoMobile);

import Acciones from "@/components/acciones/Acciones";
Vue.component("Acciones", Acciones);

import AccionPrimaria from "@/components/acciones/AccionPrimaria";
Vue.component("AccionPrimaria", AccionPrimaria);

import AccionSecundaria from "@/components/acciones/AccionSecundaria";
Vue.component("AccionSecundaria", AccionSecundaria);

import AccionesSecundarias from "@/components/acciones/AccionesSecundarias";
Vue.component("AccionesSecundarias", AccionesSecundarias);

import AccionEliminar from "@/components/acciones/AccionEliminar";
Vue.component("AccionEliminar", AccionEliminar);

import Paginacion from "@/components/Paginacion";
Vue.component("paginacion", Paginacion);
