import * as session from "@/services/session.js";

const state = {
  usuarioId: null,
  esAdmin: null,
  csrfToken: null,
  invalidLogin: false,
};

const mutations = {
  login(state, payload) {
    state.usuarioId = payload.usuarioId;
    state.esAdmin = false;
    state.csrfToken = payload.csrfToken;
    state.invalidLogin = false;
  },
  logout(state) {
    state.usuarioId = null;
    state.esAdmin = false;
    state.csrfToken = null;
    state.invalidLogin = false;
  },
  setInvalidLogin(state, invalid) {
    state.invalidLogin = invalid;
  },
};

const actions = {
  async login({ commit }, [usuario, clave]) {
    try {
      const currentSession = await session.login(usuario, clave);
      commit("login", currentSession);
    } catch (err) {
      commit("setInvalidLogin", true);
    }
  },
  async logout({ commit }) {
    await session.logout();
    commit("logout");
  },
  async recover({ commit }) {
    try {
      const currentSession = await session.recover();
      commit("login", currentSession);
      return true;
    } catch (err) {
      return false;
    }
  },
};

const getters = {
  loggedIn: (state) => {
    return state.usuarioId > 0;
  },
  invalidLogin: (state) => state.invalidLogin,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
