<template>
  <b-button-group size="sm">
    <b-button :variant="variant" class="actionButton" v-bind="$attrs">
      <v-icon variant="primary" :name="icon" width="16" v-if="icon" />
      <span>{{ label }}</span>
    </b-button>
  </b-button-group>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "outline-primary",
    },
  },
};
</script>
