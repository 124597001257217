export function listar(idCentro) {
  return {
    plantillasRadar: [
      {
        id: 1,
        nombre: "Plantilla 1",
      },
      {
        id: 2,
        nombre: "Plantilla 2",
      },
      {
        id: 3,
        nombre: "Plantilla 3",
      },
      {
        id: 4,
        nombre: "Plantilla 4",
      },
    ],
  };
}
