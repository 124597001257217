export function listar() {
  return {
    cuadrosMando: [
      {
        id: 1,
        nombre: "Índice de Accidentabilidad",
        activo: 1,
        principal: 1,
        disposicion: 3,
      },
      {
        id: 2,
        nombre: "Cuadro de Mando - DOS",
        activo: 0,
      },
      {
        id: 3,
        nombre: "Cuadro de Mando - TRES",
        activo: 0,
      },
      {
        id: 4,
        nombre: "Cuadro de Mando - CUATRO",
        activo: 1,
      },
      {
        id: 5,
        nombre: "Cuadro de Mando - CINCO",
        activo: 0,
      },
      {
        id: 6,
        nombre: "Cuadro de Mando - SEIS",
        activo: 1,
      },
      {
        id: 7,
        nombre: "Cuadro de Mando - SIETE",
        activo: 1,
      },
    ],
    hayPaginaAnterior: false,
    hayPaginaSiguiente: false,
    numeroPaginas: 1,
    pagina: 1,
    resultadosPorPagina: 15,
    totalItems: 4,
  };
}

export function cargar() {
  return {
    id: 4,
    nombre: "Cuadro de Mando - CUATRO",
    activo: 1,
    disposicion: 3,
  };
}

export function valores() {
  return {
    valores: [
      {
        id: 1,
        bloque: 2,
        tipoGrafica: 1,
        orden: 0,
        indicador: {
          nombre: "Ausencias",
          id: 2,
        },
        mediciones: [
          {
            valor: 10,
            fecha: "2021-02-10",
          },
          {
            valor: 7,
            fecha: "2020-02-23",
          },
        ],
      },
      {
        id: 3,
        bloque: 3,
        tipoGrafica: 1,
        orden: 2,
        indicador: {
          nombre: "Ausencias",
          id: 2,
        },
        mediciones: [
          {
            valor: 10,
            fecha: "2021-02-10",
          },
          {
            valor: 7,
            fecha: "2020-02-23",
          },
        ],
      },
      {
        id: 2,
        bloque: 1,
        tipoGrafica: 1,
        orden: 3,
        indicador: {
          nombre: "Ausencias",
          id: 2,
        },
        mediciones: [
          {
            valor: 10,
            fecha: "2021-02-10",
          },
          {
            valor: 7,
            fecha: "2020-02-23",
          },
        ],
      },
    ],
  };
}

export function cargarDisenio() {
  return {
    disenio: [
      {
        indicadorId: 1,
        indicadorNombre: "Indicador - UNO",
        cuadroMandoId: 1,
        bloque: 2,
        tipoGrafica: 1,
        orden: 0,
      },
      {
        indicadorId: 7,
        indicadorNombre: "Indicador - SIETE",
        cuadroMandoId: 1,
        bloque: 1,
        tipoGrafica: 1,
        orden: 0,
      },
      {
        indicadorId: 4,
        indicadorNombre: "Indicador - CUATRO",
        cuadroMandoId: 1,
        bloque: 3,
        tipoGrafica: 1,
        orden: 0,
      },
      {
        indicadorId: 2,
        indicadorNombre: "Indicador - DOS",
        cuadroMandoId: 1,
        bloque: 2,
        tipoGrafica: 1,
        orden: 0,
      },
    ],
  };
}
