<template>
  <div style="clear: both">
    <paginate
      v-show="pageCount > 0"
      :value="page"
      :page-count="pageCount"
      :page-range="pageRange"
      :margin-pages="marginPages"
      :click-handler="paginar"
      container-class="pagination"
      page-class="page-item"
      prev-text="Anterior"
      next-text="Siguiente"
      next-class="next-item"
      prev-class="prev-item"
    >
    </paginate>
  </div>
</template>
<script>
//paginate
const VuejsPaginate = () => import("vuejs-paginate");

export default {
  components: {
    paginate: VuejsPaginate,
  },
  props: {
    url: String,
    page: Number,
    pageCount: Number,
    pageRange: {
      type: Number,
      default: 5,
    },
    marginPages: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    paginar(pagina) {
      var query = Object.assign({}, this.$route.query);
      query.pagina = pagina;

      var route = {
        path: this.$route.path,
        query: query,
      };

      this.$router.push(route);
    },
  },
};
</script>
