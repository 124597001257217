<template>
  <b-form-group :label="$t('Estado')" class="comboFiltro" label-cols="4">
    <b-form-select class="mb-2" required size="sm" ref="comboEstado" @change="cambiarEstado">
      <b-form-select-option v-for="estado in estados" :value="estado.id" :key="estado.id" v-model="selected">
        {{ estado.nombre }}
      </b-form-select-option>
    </b-form-select>
  </b-form-group>
</template>
<script>
export default {
  props: {
    estados: {
      type: Array,
      required: true,
    },
  },
  computed: {},
  methods: {
    cambiarActivo(evt) {
      var query = Object.assign({}, this.$route.query);
      query.activo = this.campo;
      query.activo = evt;

      query.pagina = 1;
      var route = {
        path: this.$route.path,
        query: query,
      };

      this.$router.push(route);
    },
  },
};
</script>
