<template>
  <b-container :fluid="fluid" class="mt-md-4">
    <div id="page-container">
      <div id="page-content">
        <b-card :title="title" title-tag="h2" :id="id" class="page-card">
          <div style="text-align: right">
            <slot name="toolbar"></slot>
          </div>
          <b-card-body>
            <slot></slot>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </b-container>
</template>
<script>
export default {
  props: {
    id: String,
    title: String,
    fluid: {
      type: String,
      default: "",
    },
  },
};
</script>
