<template>
  <b-dropdown-item size="sm" v-bind="$attrs" @click="$emit('click', arguments[0])">
    <v-icon :variant="variant" :name="icon" class="mr-1" width="16" v-if="icon" />
    {{ label }}
  </b-dropdown-item>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "primary",
    },
  },
};
</script>
