<template>
  <div class="mt-2 text-right botoneraEdicion">
    <slot></slot>

    <b-button
      v-if="!ocultarCancelar"
      type="button"
      class="ml-2"
      variant="outline-secondary"
      @click="confirmarCancelacion"
    >
      Cancelar
    </b-button>
    <b-button class="ml-2" type="submit" variant="primary"> Aceptar </b-button>
  </div>
</template>

<script>
export default {
  props: {
    urlCancelar: String,
    ocultarCancelar: {
      type: Boolean,
      default: false,
    },
    confirmar: {
      type: Boolean,
      default: true,
    },
    textoConfirmacion: {
      type: String,
    },
  },
  methods: {
    confirmarCancelacion() {
      if (!this.confirmar) {
        this.cancelar();
      } else {
        const t = this.textoConfirmacion || "¿Está seguro?";
        this.$bvModal
          .msgBoxConfirm(t, {
            okTitle: "Aceptar",
            cancelTitle: "Cancelar",
          })
          .then((ok) => {
            if (ok) {
              this.cancelar();
            }
          });
      }
    },
    cancelar() {
      if (this.urlCancelar) {
        this.$router.push(this.urlCancelar);
      } else {
        this.$emit("cancel");
      }
      //this.$router.back();
    },
  },
};
</script>
