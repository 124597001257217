export function listar() {
  return {
    indicadores: [
      {
        id: 1,
        nombre: "Indicador - UNO",
        origenDatosId: 3,
        naturaleza: 1,
        activo: 1,
        conDatos: true,
      },
      {
        id: 2,
        nombre: "Indicador - DOS",
        origenDatosId: 4,
        naturaleza: 2,
        activo: 0,
        conDatos: false,
      },
      {
        id: 3,
        nombre: "Indicador - TRES",
        origenDatosId: 3,
        naturaleza: 1,
        activo: 0,
        conDatos: true,
      },
      {
        id: 4,
        nombre: "Indicador - CUATRO",
        origenDatosId: 2,
        naturaleza: 1,
        activo: 1,
        conDatos: true,
      },
      {
        id: 5,
        nombre: "Indicador - CINCO",
        origenDatosId: 1,
        naturaleza: 2,
        activo: 0,
        conDatos: false,
      },
      {
        id: 6,
        nombre: "Indicador - SEIS",
        origenDatosId: 2,
        naturaleza: 1,
        activo: 1,
        conDatos: false,
      },
      {
        id: 7,
        nombre: "Indicador - SIETE",
        origenDatosId: 1,
        naturaleza: 2,
        activo: 1,
        conDatos: true,
      },
    ],
    hayPaginaAnterior: false,
    hayPaginaSiguiente: false,
    numeroPaginas: 1,
    pagina: 1,
    resultadosPorPagina: 15,
    totalItems: 4,
  };
}

export function cargar() {
  return {
    id: 7,
    nombre: "Indicador - SIETE",
    origenDatosId: 3,
    naturaleza: 2,
    activo: 1,
    // tipoIndicador: 3,
    tipoIndicador: 1,
  };
}

export function listarAdelantados() {
  return {
    indicadores: [
      {
        id: 1,
        nombre: "Indicador - UNO",
        origenDatosId: 3,
        naturaleza: 1,
        activo: 1,
        conDatos: true,
      },
      {
        id: 3,
        nombre: "Indicador - TRES",
        origenDatosId: 3,
        naturaleza: 1,
        activo: 0,
        conDatos: true,
      },
      {
        id: 6,
        nombre: "Indicador - SEIS",
        origenDatosId: 2,
        naturaleza: 1,
        activo: 1,
        conDatos: false,
      },
    ],
  };
}
