<template>
  <page class="indicadores-predictivos-page">
    <div class="text-center mb-4">
      <h1>Bienvenido</h1>
    </div>
    <b-row>
      <b-col md="12" class="mb-3">
        <b-card :header="$t('Objetivo')" header-tag="header" header-bg-variant="primary" header-text-variant="white">
          <p>
            Esta plataforma, pretende ser un faro en la toma de decisiones de las áreas de Seguridad y Salud en el
            Trabajo, de manera que repercuta directamente en los objetivos que como compañía deben respetar. <br />
            Gracias a los modelos predictivos, con Delfos, los distintos responsables contarán con una potente
            herramienta que les aportará un análisis masivo de datos, para determinar los grados de correlación, una
            aproximación futura posible en base a escenarios o simulacros futuros y posibilidad de determinar cuales son
            las principales causas que motivan estos resultados según reconocimiento del lenguaje natural.
          </p>
        </b-card>
      </b-col>
      <b-col md="6" class="mb-3">
        <b-card no-body header-bg-variant="primary" header-text-variant="white">
          <template #header>
            La problemática
            <b-button
              v-b-toggle.problematica
              variant="dark"
              size="sm"
              class="float-right btnDesplegable"
              @click="changeButtonText('collapse-problematica')"
            >
              <v-icon name="eye" width="20" @click="changeButtonText"></v-icon>
              <span id="collapse-problematica"> {{ $t("Leer más") }}</span>
            </b-button>
          </template>
          <b-collapse id="problematica" class="mt-2">
            <b-card-body>
              <b-card-text>
                La realidad que nos rodea es eminentemente compleja, y establecer controles absolutos sobre los procesos
                productivos o de prestación de servicio contienen una gran cantidad de variables que pueden desencadenar
                en una merma en la Seguridad y Salud Ocupacional. <br />
                Así pues, existe una tendencia manifiesta a ser mejores, más eficientes, más rentables, más adaptables y
                todo ello en un ambiente de gran incertidumbre y variabilidad. <br />
                Por ello, los responsables de seguridad y salud ocupacional, en su búsqueda de la prevención, necesitan
                predecir lo que podría pasar ante distintos escenarios con información pasada, situación actual, así
                como actividades o situaciones previstas en el futuro.
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card no-body header-bg-variant="primary" header-text-variant="white">
          <template #header>
            La realidad
            <b-button
              v-b-toggle.realidad
              variant="dark"
              size="sm"
              class="float-right btnDesplegable"
              @click="changeButtonText('collapse-realidad')"
            >
              <v-icon name="eye" width="20"></v-icon>
              <span id="collapse-realidad"> {{ $t("Leer más") }}</span>
            </b-button>
          </template>
          <b-collapse id="realidad" class="mt-2">
            <b-card-body>
              <b-card-text>
                En cualquier aspecto de la Gestión, se pretende que la toma de decisiones sea en base a información
                futura, es decir, con efectos que no sean visibles en el presente o en el pasado, sino que esperamos que
                una decisión sea efectiva en el futuro. Con ello, cada vez más, se requieren herramientas que permitan a
                los distintos responsables poder conocer de manera predictiva lo que va a pasar en base a una serie de
                variables pasadas, presentes y futuras.
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-col>
      <b-col md="6" class="mt-3">
        <b-card no-body header-bg-variant="primary" header-text-variant="white">
          <template #header>
            Modelos
            <b-button
              v-b-toggle.modelos
              variant="dark"
              size="sm"
              class="float-right btnDesplegable"
              @click="changeButtonText('collapse-modelos')"
            >
              <v-icon name="eye" width="20"></v-icon>
              <span id="collapse-modelos"> {{ $t("Leer más") }}</span>
            </b-button>
          </template>
          <b-collapse id="modelos" class="mt-2">
            <b-card-body>
              <b-card-text>
                <p>
                  <b>Modelo descriptivo</b><br />
                  Se pretende conocer la situación actual, de manera que en base a los atributos dados de carácter
                  primordial, se agrupará la información para poder establecer pautas de comportamiento. <br />
                  Por ejemplo, número de incidentes ocurridos por causa subestandar.
                </p>
                <p>
                  <b> Modelos predictivos </b><br />
                  Con el análisis de la información, se pretende conocer lo que va a pasar en el futuro, predecir el
                  suceso o sucesos en base a variables supuestas. <br />
                  Por ejemplo, conocer el número de lesiones en la espalda mediante observaciones planeadas cuanta
                  inversión en capacitación se consigue reducir la accidentabilidad. <br />
                </p>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-col>
      <b-col md="6" class="mt-3">
        <b-card no-body header-bg-variant="primary" header-text-variant="white">
          <template #header>
            Tipos de indicadores
            <b-button
              v-b-toggle.indicadores
              variant="dark"
              size="sm"
              class="float-right btnDesplegable"
              @click="changeButtonText('collapse-indicadores')"
            >
              <v-icon name="eye" width="20"></v-icon>
              <span id="collapse-indicadores"> {{ $t("Leer más") }}</span>
            </b-button>
          </template>
          <b-collapse id="indicadores" class="mt-2">
            <b-card-body>
              <b-card-text>
                <p>
                  Podemos considerar con respecto a la oportunidad de la toma de decisión, dos grandes tipos de
                  Indicadores:
                  <br />
                  - <b>Indicadores rezagados</b> <br />
                  Erikson (2009) los define como la medida de resultados de seguridad y salud y que proporcionan una
                  medida del rendimiento pasado.<br /><br />
                  - <b>Indicadores Adelantados</b> <br />
                  Kjellén (2009) define el indicador adelantado en la seguridad como un indicador que cambia antes de
                  que haya cambiado el nivel actual de riesgo en la organización.
                </p>
              </b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-col>
    </b-row>
  </page>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    changeButtonText(id) {
      var txtBtn = document.getElementById(id);
      if (txtBtn.textContent.includes("más")) {
        txtBtn.textContent = " Leer menos";
      } else {
        txtBtn.textContent = " Leer más";
      }
    },
  },
};
</script>
<style>
header {
  text-align: center;
}
.btnDesplegable {
  /*background: #5e2439 !important;*/
  background: white !important;
  color: #456225 !important;
}
/*.btnDesplegable:hover {
  border-color: white !important;
}*/
</style>
