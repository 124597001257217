<template>
  <div>
    <p></p>
    <p></p>
    <b-nav-item :title="$t('Administración')" :disabled="true">
      <!-- <v-icon name="settings" width="16" /> -->
      <span>{{ $t("Administración") }}</span>
    </b-nav-item>

    <div class="menu-administracion submenu" v-show="visible">
      <b-nav-item to="/administracion/cuadros-de-mando">
        <v-icon name="monitor" width="16" />
        <span>{{ $t("Cuadros de mando") }}</span>
      </b-nav-item>
      <b-nav-item :to="`/administracion/indicadores`">
        <v-icon name="check-square" width="16" />
        <span>{{ $t("Indicadores") }}</span>
      </b-nav-item>
      <b-nav-item :to="`/administracion/zonas`">
        <v-icon name="map-pin" width="16" />
        <span>{{ $t("Zonas") }}</span>
      </b-nav-item>
      <b-nav-item :to="`/administracion/causas`">
        <v-icon name="list" width="16" />
        <span>{{ $t("Causas") }}</span>
      </b-nav-item>
      <b-nav-item to="/administracion/origenes-de-datos" :title="$t('Orígenes de datos')">
        <v-icon name="database" width="16" />
        <span>{{ $t("Orígenes de datos") }}</span>
      </b-nav-item>
      <b-nav-item to="/usuarios" :title="$t('Usuarios')">
        <v-icon name="users" width="16" />
        <span>{{ $t("Usuarios") }}</span>
      </b-nav-item>
    </div>
  </div>
</template>

<script>
export default {
  data: () => {
    return {
      visible: true,
    };
  },
};
</script>
