import * as apiCuadrosMando from "@/services/cuadrosMando.js";

function filtrosPorDefecto() {
  const desde = new Date();
  const hasta = new Date();
  desde.setFullYear(hasta.getFullYear() - 1);
  return {
    causa: "",
    zona: "",
    desde: desde,
    hasta: hasta,
  };
}

const state = {
  cuadroMandoId: 0,
  cuadroMando: {
    activo: "",
    principal: "",
    disposicion: "",
    id: "",
    creadoEl: "",
    nombre: "",
  },
  indicadores: {},
  disenio: [],
  indicadores: {},
  valores: {},
  cuadros: [],
  zonas: [],
  wordcloudCausas: [],
  filtros: filtrosPorDefecto(),
};

const mutations = {
  setCuadroMandoId(state, id) {
    state.cuadroMandoId = id;
  },
  setCuadroMando(state, payload) {
    state.cuadroMando = payload;
  },
  setDisenio(state, payload) {
    state.disenio = payload;
  },
  setZonas(state, zonas) {
    state.zonas = zonas;
  },
  setCausas(state, causas) {
    state.causas = causas;
  },
  setCuadros(state, cuadros) {
    state.cuadros = cuadros;
  },
  setFiltros(state, filtros) {
    state.filtros = filtros;
  },
  setZona(state, zona) {
    state.filtros.zona = zona;
  },
  setDesde(state, desde) {
    state.filtros.desde = desde;
  },
  setHasta(state, hasta) {
    state.filtros.hasta = hasta;
  },
  resetFiltros(state) {
    state.filtros = filtrosPorDefecto();
  },
  setIndicadores(state, indicadores) {
    const result = {};
    for (var indicador of indicadores) {
      result[indicador.id] = indicador;
    }
    state.indicadores = result;
  },
  setValores(state, valores) {
    state.valores = valores;
  },
  setWordcloudCausas(state, valores) {
    state.wordcloudCausas = valores;
  },
};

// Lo que puede hacer el usuario
const actions = {
  async cargarDashboard(context, cuadroMandoId) {
    await context.dispatch("cargarCuadros");

    // Si recibo el cuadro de mando, lo cargo
    if (!cuadroMandoId) {
      const cuadro = context.state.cuadros.find((cm) => cm.principal);
      if (cuadro) {
        cuadroMandoId = cuadro.id;
      }
    }

    if (cuadroMandoId) {
      context.commit("setCuadroMandoId", cuadroMandoId);
      await Promise.all([context.dispatch("cargarValores"), context.dispatch("cargarZonas")]);
    } else {
      throw Error("No se ha encontrado el cuadro de mando");
    }
  },
  async cargarCuadros(context) {
    const response = await apiCuadrosMando.listar();
    const cuadros = response.cuadrosMando;
    context.commit("setCuadros", cuadros);
  },
  async cargarValores(context) {
    const cuadroMandoId = context.state.cuadroMandoId;
    const filtros = context.state.filtros;
    const response = await apiCuadrosMando.valores(cuadroMandoId, filtros);

    context.commit("setCuadroMandoId", response.cuadroMando.id);
    context.commit("setCuadroMando", response.cuadroMando);
    context.commit("setDisenio", response.disenio);
    context.commit("setIndicadores", response.indicadores);
    context.commit("setValores", response.valores);
    context.commit("setWordcloudCausas", response.wordcloudCausas);
  },
  async cargarZonas(context) {
    const cuadroMandoId = context.state.cuadroMandoId;
    const zonas = await apiCuadrosMando.zonasUsadas(cuadroMandoId);
    context.commit("setZonas", zonas);
  },
  async cambiarCuadro(context, cuadroMandoId) {
    context.commit("setCuadroMandoId", cuadroMandoId);
    context.commit("resetFiltros");
    await Promise.all([context.dispatch("cargarValores"), context.dispatch("cargarZonas")]);
  },
  async aplicarFiltros(context) {
    await context.dispatch("cargarValores");
  },
};

const getters = {
  hayValores: (state) => {
    return Object.keys(state.valores).length > 0;
  },
  getIndicador: (state) => (id) => {
    return state.indicadores[id];
  },
  getValores: (state) => (indicadorId) => {
    return state.valores[indicadorId];
  },
  indicadoresParaBloque: (state) => (bloque) => {
    const cms = state.disenio.filter((cm) => cm.bloque == bloque);
    const ids = cms.map((cm) => cm.indicadorId);
    const indicadores = ids.map((id) => state.indicadores[id]);
    return indicadores;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
