<template>
  <b-form @submit.prevent="submit" style="width: 100%">
    <b-input-group size="sm">
      <template v-slot:prepend>
        <b-dropdown
          :text="columnaSelected"
          variant="primary"
          size="sm"
          class="tablet"
          :title="$t('Seleccionar Columna')"
        >
          <b-dropdown-item
            @click="cambioColumna($event, columna)"
            v-for="columna in columnas"
            v-bind:key="columna.campo"
            >{{ columna.columna }}</b-dropdown-item
          >
          <b-dropdown-item @click="resetCampo()">
            {{ $t("Todos") }}
          </b-dropdown-item>
        </b-dropdown>
        <b-button
          class="desktop"
          v-if="columnaSeleccionada"
          variant="primary"
          size="sm"
          @click="resetCampo()"
          :title="$t('Quitar columna seleccionada')"
        >
          <v-icon variant="primary" name="x" width="16"></v-icon>
        </b-button>
      </template>

      <b-form-input v-model="search" type="search" size="sm" :placeholder="`${$t('Búsqueda')} ${campo}`">
      </b-form-input>
      <b-input-group-append>
        <b-button variant="primary" size="sm" type="submit">
          <v-icon variant="primary" name="search" width="16"></v-icon>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <input type="submit" hidden />
  </b-form>
</template>
<script>
export default {
  props: {
    columnas: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      search: "",
      campo: this.value,
      columnaSelected: this.value,
    };
  },
  computed: {
    columnaSeleccionada() {
      return this.campo != "";
    },
  },
  methods: {
    cambioColumna(e, columna) {
      e.stopPropagation;
      this.columnaSelected = columna.columna;
      this.campo = columna.campo;
    },
    submit() {
      var query = Object.assign({}, this.$route.query);
      delete query["busqueda"];
      delete query["busquedaColumna"];
      if (this.campo != "") {
        query.busquedaColumna = {};
        query.busquedaColumna.campo = this.campo;
        query.busquedaColumna.term = this.search;
      } else {
        query.busqueda = this.search;
      }
      this.search = "";
      this.$router.push({
        path: this.$route.path,
        query: query,
      });
    },
    resetCampo() {
      this.campo = "";
      this.columnaSelected = "";
    },
    obtenerIndiceColumna(campo, columnas) {
      return Object.keys(columnas).find((key) => columnas[key] === campo);
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.campo = val;
        /* let indiceColumna = this.obtenerIndiceColumna(
                    val,
                    this.columnas
                );*/
      },
      deep: true,
    },
  },
};
</script>
